import ExternalLink from "@components/ExternalLink";
import styled, { css } from "styled-components";
import type { ModalSize } from "./ModalSizeContext";

type ModalButtonProps = {
  $size: ModalSize;
};

const ModalButton = styled(ExternalLink)<ModalButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: ${({ theme }) => theme.borderWidth[0]};
  color: white;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: #a238ff;

  &:hover {
    cursor: pointer;
  }

  ${({ $size }) => {
    switch ($size) {
      case "small": {
        return css`
          font-size: ${({ theme }) => theme.fontSize.xs};
          padding: ${({ theme }) =>
            `${theme.spacing[10]} ${theme.spacing[18]}`};
          margin-top: ${({ theme }) => theme.spacing[15]};
        `;
      }
      case "normal": {
        return css`
          font-size: ${({ theme }) => theme.fontSize.base};
          padding: ${({ theme }) =>
            `${theme.spacing[16]} ${theme.spacing[20]}`};
          margin-top: ${({ theme }) => theme.spacing[20]};
        `;
      }
      case "large": {
        return css`
          font-size: ${({ theme }) => theme.fontSize.lg};
          padding: ${({ theme }) =>
            `${theme.spacing[18]} ${theme.spacing[25]}`};
          margin-top: ${({ theme }) => theme.spacing[20]};
        `;
      }
      default: {
        return css``;
      }
    }
  }}
`;

export default ModalButton;
