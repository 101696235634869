import { useCallback, useContext, useEffect, useState } from "react";
import WidgetPreviewContext from "@portal/components/WidgetPreviewContext";
import { useSizeMeasurer } from "./SizeMeasurerProvider";

const WIDTH_LARGE_BREAKPOINT = 480;
const WIDTH_NORMAL_BREAKPOINT = 300;
const HEIGHT_LARGE_BREAKPOINT = 250;
const HEIGHT_NORMAL_BREAKPOINT = 200;

export type ModalSize = "large" | "normal" | "small";

export const useModalSize = () => {
  const previewContext = useContext(WidgetPreviewContext);
  const [modalSize, setModalSize] = useState<ModalSize>("small");
  const containerDimensions = useSizeMeasurer();

  const measure = useCallback(() => {
    if (!containerDimensions) return;
    const { width, height } = containerDimensions;

    let nextSize: ModalSize = "large";
    if (width < WIDTH_LARGE_BREAKPOINT || height < HEIGHT_LARGE_BREAKPOINT) {
      nextSize = "normal";
    }

    if (
      width <= WIDTH_NORMAL_BREAKPOINT ||
      height <= HEIGHT_NORMAL_BREAKPOINT
    ) {
      nextSize = "small";
    }

    setModalSize(nextSize);
  }, [containerDimensions]);

  useEffect(() => {
    if (!containerDimensions) return;
    measure();
  }, [containerDimensions, measure]);

  // Perform mesure if preview context changes
  useEffect(() => {
    if (previewContext) {
      measure();
    }
  }, [measure, previewContext]);

  return modalSize;
};
