import "focus-visible";
import "./global.scss";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import ModalError from "@widget/components/ModalError";
import {
  TempoProvider,
  cookieStorageManagerSSR,
  extendTheme,
  localStorageManager,
} from "@tempo/core";
import { translate } from "@deezer/i18n";
import { cookies } from "next/dist/client/components/headers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import sentryConfig from "src/sentryConfig";

const queryConfig = { queries: { refetchOnWindowFocus: false, retry: 0 } };
export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

const theme = extendTheme({
  fonts: {
    heading: "Deezer Product, Arial, sans-serif",
  },
  styles: {
    global: {
      "html, body": {
        background: "transparent",
      },
    },
  },
  config: {
    initialColorMode: "light",
  },
});

const colorModeManager =
  typeof cookies === "string"
    ? cookieStorageManagerSSR(cookies)
    : localStorageManager;

type Props = {
  Component: new (...args: any[]) => React.Component<any, any>; // eslint-disable-line
  pageProps: Record<string, unknown>;
};

function App({ Component, pageProps }: Props) {
  const localstorageAllowed = useLocalStorageAllowed();

  useEffect(() => {
    if (localstorageAllowed) {
      Sentry.init(sentryConfig);
    }
  }, [localstorageAllowed]);

  return (
    <QueryClientProvider client={queryClient}>
      <TempoProvider theme={theme} colorModeManager={colorModeManager}>
        <Sentry.ErrorBoundary
          fallback={
            <ModalError
              error={{
                label: translate(
                  "errormessage_text_erroshasoccurredpleasetryagain_widget"
                ),
                reloadCTA: true,
              }}
            />
          }
        >
          <div suppressHydrationWarning>
            <Component {...pageProps} />
          </div>
        </Sentry.ErrorBoundary>
      </TempoProvider>
    </QueryClientProvider>
  );
}

export default App;

const useLocalStorageAllowed = () => {
  const [allowed, setAllowed] = useState<boolean>();
  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        const localStorage = window.localStorage; // eslint-disable-line
        setAllowed(true);
      } catch (e) {
        // Localstorage is not allowed, do not use sentry
        setAllowed(false);
      }
    }
  }, []);
  return allowed;
};
