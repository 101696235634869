const sentryConfig = {
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.SENTRY_DSN,

  /**
   * List found in the official docs: https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
   * + in the community gist: https://gist.github.com/impressiver/5092952
   */
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",

    // Custom errors we ignore based on real-world usage
    "$ is not defined", // https://sentry.io/organizations/deezer/issues/2298368019 + we don't even use jQuery!
    "ResizeObserver loop limit exceeded", // Stackoverflow: you can safely ignore this error
    "ResizeObserver loop completed with undelivered notifications.", // Stackoverflow: you can safely ignore this error
    "NetworkError when attempting to fetch resource.", // Ignore network error
    "Failed to fetch", // Ignore network error
    "Abgebrochen", // Ignore network error
    "AbortError: The operation was aborted", // Ignore firefox error bug https://bugzilla.mozilla.org/show_bug.cgi?id=1507193
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Mozilla extensions
    /^moz-extension:\/\//i,
    // Safari extensions
    /^safari-extension:/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};

export default sentryConfig;
