import { useModalSize } from "@widget/components/ModalSizeContext";
import React from "react";
import styled, { css } from "styled-components";

type Size = "small" | "normal" | "large";

type Props = {
  text: string;
  children: React.ReactNode;
  isVisible: boolean;
  transparent?: boolean;
  closeModal?: () => void;
  fullscreen?: boolean;
};

const Modal: React.FC<Props> = ({
  text,
  children,
  isVisible,
  closeModal,
  transparent,
  fullscreen,
  ...props
}) => {
  const size = useModalSize();
  return (
    <Container
      $isVisible={isVisible}
      $transparent={transparent}
      $fullscreen={fullscreen}
      {...props}
    >
      {closeModal ? (
        <CloseBtn onClick={closeModal} data-testid="widget-modal-close">
          {size === "large" ? <BigCloseIcon /> : <SmallCloseIcon />}
          <span className="sr-only">Close ad</span>
        </CloseBtn>
      ) : null}
      <Title $size={size} data-testid="widget-modal-text">
        {text}
      </Title>
      {children}
    </Container>
  );
};

export default Modal;

type ContainerProps = {
  $isVisible: boolean;
  $transparent?: boolean;
  $fullscreen?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  color: ${({ theme }) => theme.colors.whitesmoke};
  top: ${({ $isVisible, theme }) => {
    if ($isVisible) return 0;
    if (theme.fullscreen) return "100vh";
    return "100%";
  }};
  left: 0;
  z-index: ${({ $isVisible }) => ($isVisible ? 100 : 0)};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  height: 100%;
  width: 100%;
  background-color: ${({ theme, $transparent }) =>
    $transparent ? "rgb(0, 0, 0, 0.7)" : theme.colors.softBlack};
  transition: opacity 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme, $fullscreen }) =>
    $fullscreen &&
    theme.playerRadius &&
    css`
      border-radius: ${theme.borderRadius[10]};
    `}
`;

type SizeProp = {
  $size: Size;
};

const Title = styled.h1<SizeProp>`
  text-align: center;

  ${({ $size }) => {
    switch ($size) {
      case "small": {
        return css`
          font-size: ${({ theme }) => theme.fontSize.lg};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          max-width: 66%;
          line-height: 1;
        `;
      }
      case "normal": {
        return css`
          font-size: ${({ theme }) => theme.fontSize["3xl"]};
          font-weight: ${({ theme }) => theme.fontWeight.bold};
          padding: 0 ${({ theme }) => theme.spacing[30]};
        `;
      }
      case "large": {
        return css`
          font-size: ${({ theme }) => theme.fontSize["4xl"]};
          font-weight: ${({ theme }) => theme.fontWeight.bold};
          padding: 0 ${({ theme }) => theme.spacing[15]};
        `;
      }
      default: {
        return css``;
      }
    }
  }}
`;

const CloseBtn = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing[10]};
  right: ${({ theme }) => theme.spacing[10]};
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BigCloseIcon = styled.svg.attrs({
  width: 18,
  height: 18,
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00008 9.70712L0.707108 18.0001L0 17.293L8.29298 9.00001L0.000108719 0.707143L0.707215 3.58522e-05L9.00008 8.2929L17.2931 -0.00012207L18.0002 0.706985L9.70719 9.00001L18.0003 17.2931L17.2932 18.0003L9.00008 9.70712Z"
      fill="white"
    />
  ),
})``;

const SmallCloseIcon = styled.svg.attrs({
  width: 15,
  height: 15,
  viewBox: "0 0 15 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00242 7.70941L13.2969 14.0039L14.004 13.2968L7.70953 7.0023L14.0044 0.70747L13.2972 0.000363171L7.00242 6.29519L0.707107 -0.00012207L0 0.706985L6.29531 7.0023L0.000342369 13.2973L0.707449 14.0044L7.00242 7.70941Z"
      fill="white"
    />
  ),
})``;
