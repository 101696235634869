import WidgetPreviewContext from "@portal/components/WidgetPreviewContext";
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from "react";

export type DimensionObject = {
  width: number;
  height: number;
};

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
  };
}

export type DimensionRefSetter = (ref: HTMLDivElement | null) => void;

type UseDimensionsHook = [DimensionRefSetter, DimensionObject | undefined];

function useDimensions(): UseDimensionsHook {
  const previewContext = useContext(WidgetPreviewContext);
  const [dimensions, setDimensions] = useState<DimensionObject | undefined>();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const measure = useCallback(() => {
    if (ref) {
      window.requestAnimationFrame(() => {
        setDimensions(getDimensionObject(ref));
      });
    }
  }, [ref]);

  useEffect(() => {
    if (ref) {
      measure();

      window.addEventListener("resize", measure);
      window.addEventListener("scroll", measure);

      return () => {
        if (!ref) return;
        window.removeEventListener("resize", measure);
        window.removeEventListener("scroll", measure);
      };
    }

    /* eslint-disable-next-line */
    return () => {};
  }, [measure, ref]);

  // When preview context updates changes, re-calculate all dimensions
  useEffect(() => {
    if (previewContext) {
      measure();
    }
  }, [measure, previewContext]);

  // While dimension is still equal to zero, try to measure it regularly
  // because it might be cause a race condition issue
  useEffect(() => {
    if (dimensions) {
      if (dimensions.width === 0 || dimensions.height === 0) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(measure, 300);
      }
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [dimensions, measure]);

  const handleRefChange = useCallback(
    (newRef: HTMLDivElement | null) => {
      if (!ref && newRef) {
        setRef(newRef);
      }
    },
    [ref]
  );

  return useMemo(
    () => [handleRefChange, dimensions],
    [dimensions, handleRefChange]
  );
}

export default useDimensions;
