import { translate } from "@deezer/i18n";
import Modal from "@widget/components/Modal";
import React from "react";
import ModalButton from "./ModalButton";
import { useModalSize } from "./ModalSizeContext";

export type ModalErrorType = {
  label: string;
  reloadCTA?: boolean;
};

type ModalErrorProps = {
  error: ModalErrorType;
  onReloadCTAClick?: () => void;
};

const ModalError: React.FC<ModalErrorProps> = ({ error, onReloadCTAClick }) => {
  const size = useModalSize();
  if (!error) return null;

  const buttonText = translate("generic_action_gotodeezer_widget");

  return (
    <Modal
      isVisible
      text={error.label}
      fullscreen
      data-testid="widget-modal-error"
    >
      {error.reloadCTA && (
        <ModalButton
          $size={size}
          as="button"
          type="button"
          onClick={() => {
            if (onReloadCTAClick) {
              onReloadCTAClick();
            } else {
              window.location.reload();
            }
          }}
          data-testid="widget-modal-error-try-again"
        >
          {buttonText}
        </ModalButton>
      )}
    </Modal>
  );
};

export default ModalError;
