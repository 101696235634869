import React from "react";
import styled from "styled-components";
import cn from "clsx";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
}

const Anchor = styled.a`
  text-decoration: none;
`;

const ExternalLink: React.FC<Props> = (props) => {
  const { children, to, ...anchorProps } = props;
  return (
    <Anchor
      {...anchorProps}
      className={cn("font-normal", anchorProps.className)}
      target="_blank"
      rel="noopener noreferrer"
      href={to}
    >
      {children}
    </Anchor>
  );
};

export const dzrLink = (
  contextType:
    | "playlist"
    | "album"
    | "artist"
    | "artist-top-track"
    | "track"
    | "episode"
    | "show",
  contextId: string | number,
  utm?: string
) => {
  if (contextType === "artist-top-track") {
    return `https://deezer.com/artist/${contextId}/top_track?${utm}`;
  }

  return `https://deezer.com/${contextType}/${contextId}?${utm}`;
};

export default ExternalLink;
