/* eslint-disable react/display-name */
import React from "react";

const WidgetPreviewContext = React.createContext<
  { width: number | string; height: number; url: string } | undefined
>(undefined);

export default WidgetPreviewContext;

export const withWidgetPreviewContext = <P extends object>(
  Element: React.ComponentType<P>
) => {
  return React.forwardRef((props: P, ref) => {
    return (
      <WidgetPreviewContext.Consumer>
        {(previewContext) => (
          <Element previewContext={previewContext} {...props} ref={ref} />
        )}
      </WidgetPreviewContext.Consumer>
    );
  });
};