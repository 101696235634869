import type {
  DimensionObject,
  DimensionRefSetter,
} from "@hooks/useDimensions";
import useDimensions from "@hooks/useDimensions";
import React from "react";

const SizeMeasurerContext = React.createContext<DimensionObject | undefined>(
  undefined
);

export const useSizeMeasurer = () => React.useContext(SizeMeasurerContext);

type Props = {
  children: (params: { containerRef: DimensionRefSetter }) => React.ReactNode;
};

const SizeMeasurerProvider: React.FC<Props> = ({ children }) => {
  const [containerRef, containerDimensions] = useDimensions();
  return (
    <SizeMeasurerContext.Provider value={containerDimensions}>
      {children({ containerRef })}
    </SizeMeasurerContext.Provider>
  );
};

export default SizeMeasurerProvider;
